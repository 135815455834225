import React from 'react';
import styles from './Roadmap.module.scss';

const Roadmap = () => (
  <section className='container text-white tracking-wider py-12 md:py-16 lg:mx-auto lg:py-18'>
    <h2 className='font-extrabold pb-12 text-4.5xl font-Montserrat text-center'>Roadmap</h2>
    <div className='flex flex-col md:flex-row overflow-hidden lg:mx-auto' style={{ maxWidth: 640 }}>
      <div
        className={`rounded-full bg-brand w-24 h-24 text-black2 flex justify-center items-center relative flex-shrink-0 ${styles.goDown}`}
      >
        <span className={`font-extrabold font-Montserrat ml-3 mr-1 ${styles.subTitle}`}>20</span>
        <span className='font-black'>%</span>
      </div>
      <div className='flex flex-col ml-18 mt-4 mb-8 md:ml-6 md:mt-7 md:mb-12'>
        <h3 className={`font-extrabold mb-2 md:mb-7 font-Montserrat leading-10 ${styles.subTitle}`}>Merchandise</h3>
        <p className='text-gray'>
          AstroGator Fam is going to build the IP with AGF member. Ownership is given to members over their AstroGator.
        </p>
      </div>
    </div>
    <div className='flex flex-col md:flex-row overflow-hidden lg:mx-auto' style={{ maxWidth: 640 }}>
      <div
        className={`rounded-full bg-brand w-24 h-24 text-black2 flex justify-center items-center relative flex-shrink-0 ${styles.goDown}`}
      >
        <span className={`font-extrabold font-Montserrat ml-3 mr-1 ${styles.subTitle}`}>40</span>
        <span className='font-black'>%</span>
      </div>
      <div className='flex flex-col ml-18 mt-4 mb-8 md:ml-6 md:mt-7 md:mb-12'>
        <h3 className={`font-extrabold mb-2 md:mb-7 font-Montserrat leading-10 ${styles.subTitle}`}>Liquidity pool</h3>
        <p className='text-gray'>The liquidity pool will be launched, You can swap your AstroGator for AGT.</p>
      </div>
    </div>
    <div className='flex flex-col md:flex-row overflow-hidden lg:mx-auto' style={{ maxWidth: 640 }}>
      <div
        className={`rounded-full bg-brand w-24 h-24 text-black2 flex justify-center items-center relative flex-shrink-0 ${styles.goDown}`}
      >
        <span className={`font-extrabold font-Montserrat ml-3 mr-1 ${styles.subTitle}`}>60</span>
        <span className='font-black'>%</span>
      </div>
      <div className='flex flex-col ml-18 mt-4 mb-8 md:ml-6 md:mt-7 md:mb-12'>
        <h3 className={`font-extrabold mb-2 md:mb-7 font-Montserrat leading-10 ${styles.subTitle}`}>3D voxel model</h3>
        <p className='text-gray'>
          AstroGator voxel version is in progress. It will airdrop to the family members, and be your avatar on Sandbox.
        </p>
      </div>
    </div>
    <div className='flex flex-col md:flex-row overflow-hidden lg:mx-auto' style={{ maxWidth: 640 }}>
      <div
        className={`rounded-full bg-brand w-24 h-24 text-black2 flex justify-center items-center relative flex-shrink-0 ${styles.goDown}`}
      >
        <span className={`font-extrabold font-Montserrat ml-3 mr-1 ${styles.subTitle}`}>80</span>
        <span className='font-black'>%</span>
      </div>
      <div className='flex flex-col ml-18 mt-4 mb-8 md:ml-6 md:mt-7 md:mb-12'>
        <h3 className={`font-extrabold mb-2 md:mb-7 font-Montserrat leading-10 ${styles.subTitle}`}>
          Space on The Sandbox
        </h3>
        <p className='text-gray'>AstroGator Fam will have a virtual estate on Sandbox.</p>
      </div>
    </div>
    <div className='flex flex-col md:flex-row overflow-hidden lg:mx-auto' style={{ maxWidth: 640 }}>
      <div
        className={`rounded-full bg-brand w-24 h-24 text-black2 flex justify-center items-center relative flex-shrink-0 ${styles.last} ${styles.goDown}`}
      >
        <span className={`font-extrabold font-Montserrat ml-3 mr-1 ${styles.subTitle}`}>100</span>
        <span className='font-black'>%</span>
      </div>
      <div className='flex flex-col ml-18 mt-4 mb-8 md:ml-6 md:mt-7 md:mb-12'>
        <h3 className={`font-extrabold mb-2 md:mb-7 font-Montserrat leading-10 ${styles.subTitle}`}>
          Constructing AGF space
        </h3>
        <p className='text-gray'>Building family space to be GameFi scene.</p>
      </div>
    </div>
  </section>
);

export default Roadmap;
