import React, { useEffect, useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { Web3Provider } from '@ethersproject/providers';
import { formatUnits, parseUnits } from '@ethersproject/units';
import { Contract } from '@ethersproject/contracts';
import { Interface } from '@ethersproject/abi';
import { hexlify } from '@ethersproject/bytes';
import { toUtf8Bytes } from '@ethersproject/strings';

import { IWeb3Controller } from '../constants/web3ContextConstants';
import { ASTRO_ADDRESSES } from '../constants';
import ASTROGATORS_ABI from '../constants/abis/astrogators.json';
import { AstroGatorInfo, INITIAL_ASTRO_STATE } from '../constants/types';
import { shortenTxId, getEtherscanLink } from '../utils/web3Utils';

function useAstroGators(web3Controller: IWeb3Controller): [AstroGatorInfo, number, React.Dispatch<any>, () => void] {
  const { address, web3Provider, chainId } = web3Controller;

  const [astro, setAstroState] = useState<AstroGatorInfo>(INITIAL_ASTRO_STATE);
  const [tokenNumber, setTokenNumber] = useState<number>(1);

  const getAstroData = async (passWeb3Provider: Web3Provider, passChainId: number) => {
    let astrogatorContract = null;
    try {
      astrogatorContract = new Contract(ASTRO_ADDRESSES[passChainId], ASTROGATORS_ABI, passWeb3Provider);
    } catch (error) {
      // console.error('Failed to get contract', error);
      return null;
    }
    const [AstroGatorPrice, maxAstroGatorPurchase, maxAstroGators, saleIsActive, totalSupply] = await Promise.all([
      astrogatorContract.AstroGatorPrice(),
      astrogatorContract.maxAstroGatorPurchase(),
      astrogatorContract.MAX_AstroGators(),
      astrogatorContract.saleIsActive(),
      astrogatorContract.totalSupply(),
    ]);

    setAstroState({
      price: formatUnits(AstroGatorPrice),
      maxPurchase: maxAstroGatorPurchase.toString(),
      maxAstroGators: maxAstroGators.toString(),
      isActive: saleIsActive.toString(),
      totalSupply: totalSupply.toString(),
    });
  };

  const mintAstro = useCallback(async () => {
    if (web3Provider && chainId && address) {
      try {
        const price = parseUnits(astro.price);
        const astroInterface = new Interface(ASTROGATORS_ABI);
        let functionData = astroInterface.encodeFunctionData('mintAstroGator', [tokenNumber]);
        const url = new URL(location.href);
        const ref = url.searchParams.get('ref');
        if (ref) {
          const hexRef = hexlify(toUtf8Bytes(ref));
          functionData = functionData + hexRef.substr(2);
        }
        const params = [
          {
            from: address,
            to: ASTRO_ADDRESSES[chainId],
            value: price.mul(tokenNumber).toHexString(),
            data: functionData,
          },
        ];
        const txHash = await web3Provider.send('eth_sendTransaction', params);
        const toastId = toast.loading('Transaction sent! Wait for blockchain to process...', { pauseOnHover: false });
        // toast(
        //   <a href={getEtherscanLink(chainId, txHash, 'transaction')} target='_blank' rel='noreferrer'>
        //     {shortenTxId(txHash)}
        //   </a>,
        // );
        const receiptTx = await web3Provider.getTransaction(txHash);
        await receiptTx.wait();
        await getAstroData(web3Provider, chainId);
        toast.update(toastId, {
          render: 'Success!',
          type: toast.TYPE.SUCCESS,
          autoClose: 3000,
          isLoading: false,
          closeButton: true,
        });

        // toast('Success!');
      } catch (error) {
        console.error(error);
        toast.warning('Warning: User Deny');
        // toast.error(JSON.stringify(error));
        return null;
      }
    }
  }, [web3Provider, chainId, address, astro, tokenNumber]);

  useEffect(() => {
    if (web3Provider && chainId) {
      getAstroData(web3Provider, chainId);
    }
  }, [web3Provider, chainId]);

  return [astro, tokenNumber, setTokenNumber, mintAstro];
}

export default useAstroGators;
