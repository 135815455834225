import { useRef, useEffect, useReducer } from 'react';
import { Web3Provider } from '@ethersproject/providers';
import Web3Modal from 'web3modal';

import getProviderOptions from '../utils/getProviderOptions';
import { getChainData } from '../utils';
import { IWeb3State } from '../constants/web3ContextConstants';
import { INITIAL_STATE } from '../constants/web3ContextConstants';

const WEB3_ACTION_SET = 'set';

function initWeb3Provider(provider: any) {
  const web3Provider = new Web3Provider(provider);
  return web3Provider;
}

function web3StateReducer(state: IWeb3State, action: any) {
  switch (action.type) {
    case WEB3_ACTION_SET:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Not support action type: ${action.typ} in this reducer`);
  }
}

function useWeb3State() {
  const [web3State, dispatchWeb3State] = useReducer(web3StateReducer, { ...INITIAL_STATE });
  const setWeb3State = (newState: IWeb3State) => dispatchWeb3State({ type: WEB3_ACTION_SET, payload: newState });

  const web3Modal = useRef<any>(null);
  const web3StateRef = useRef<any>(web3State);
  let subscribeProvider: (provider: any) => void;

  const getNetwork = () => {
    try {
      return getChainData(web3State.chainId).network;
    } catch {
      return '';
    }
  };

  const resetApp = async () => {
    await web3Modal.current.clearCachedProvider();
    setWeb3State({ ...INITIAL_STATE });
  };

  const onConnect = async () => {
    const provider = await web3Modal.current.connect();
    const web3Provider: Web3Provider = initWeb3Provider(provider);
    const signer = web3Provider.getSigner();
    const address = await signer.getAddress();
    const network = await web3Provider.getNetwork();
    const chainId = network.chainId;
    setWeb3State({
      web3Provider,
      provider,
      connected: true,
      address,
      chainId,
    });
    return provider;
  };

  const initConnect = async () => {
    const provider = await onConnect();
    await subscribeProvider(provider);
  };

  subscribeProvider = async (provider: any) => {
    if (!provider.on) {
      return;
    }
    provider.on('disconnect', resetApp);
    provider.on('accountsChanged', async (accounts: string[]) => {
      if (accounts && accounts.length > 0) {
        dispatchWeb3State({ type: WEB3_ACTION_SET, payload: { address: accounts[0] } });
      } else {
        resetApp();
      }
    });
    provider.on('chainChanged', async (chainId: number) => {
      dispatchWeb3State({ type: WEB3_ACTION_SET, payload: { chainId } });
    });

    provider.on('networkChanged', onConnect);
  };

  useEffect(() => {
    web3Modal.current = new Web3Modal({
      network: getNetwork(),
      cacheProvider: true,
      providerOptions: getProviderOptions(),
    });
    if (web3Modal.current.cachedProvider) {
      initConnect();
    }
  }, []);

  useEffect(() => {
    web3StateRef.current = web3State;
  }, [web3State]);

  return [web3State, initConnect, resetApp];
}

export default useWeb3State;
