import React from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3Context from './context/Web3Context';
import useWeb3State from './hooks/useWeb3State';
import { Header, Footer, CollectionContent } from './components';

const Collection = () => {
  const [web3State, initConnect, resetApp] = useWeb3State();
  return (
    <Web3Context.Provider value={{ ...web3State, initConnect, resetApp }}>
      <ToastContainer />
      <div className='bg-black font-sans' style={{ minWidth: 320 }}>
        <Header active={'Collection'} />
        <main>
          <div className={`container`}>
            <CollectionContent />
          </div>
        </main>
        <Footer />
      </div>
    </Web3Context.Provider>
  );
};

export default Collection;
