import React from 'react';
import styles from './Carousel.module.scss';

const Carousel = () => (
  <section>
    <div className='overflow-hidden'>
      <div className={`space-x-6 md:space-x-12 flex ${styles.slide}`}>
        <img className={styles.img} src='images/astrogator_token-3974.png' alt='AstroGator #3974' />
        <img className={styles.img} src='images/astrogator_token-3979.png' alt='AstroGator #3979' />
        <img className={styles.img} src='images/astrogator_token-3984.png' alt='AstroGator #3984' />
        <img className={styles.img} src='images/astrogator_token-3993.png' alt='AstroGator #3993' />
        <img className={styles.img} src='images/astrogator_token-4000.png' alt='AstroGator #4000' />
        <img className={styles.img} src='images/astrogator_token-7943.png' alt='AstroGator #7943' />
        <img className={styles.img} src='images/astrogator_token-7964.png' alt='AstroGator #7964' />
        <img className={styles.img} src='images/astrogator_token-7934.png' alt='AstroGator #7934' />
        <img className={styles.img} src='images/astrogator_token-7985.png' alt='AstroGator #7985' />
        <img className={styles.img} src='images/astrogator_token-7990.png' alt='AstroGator #7990' />
        <img className={styles.img} src='images/astrogator_token-7905.png' alt='AstroGator #7905' />
        <img className={styles.img} src='images/astrogator_token-7917.png' alt='AstroGator #7917' />
      </div>
    </div>
    <div className='container lg:mx-auto py-18 flex flex-col lg:flex-row'>
      <img src='images/logo_astrogator_fam.png' className='mr-2' alt='AstroGator Fam' />
      <div className='text-white flex flex-col justify-center lg:ml-16' style={{ maxWidth: 738 }}>
        <h2 className='font-extrabold text-4.5xl pb-5 font-Montserrat'>What is AstroGator Fam?</h2>
        <p className='text-gray md:pb-12 lg:pb-0'>
          AstroGator Fam is a group of collectors in 10,000 randomly generated ERC-721 NFTs stored on the Ethereum
          blockchain. Every 1/1 AstroGator is comprised of a unique style, skin, outfit and accessory. Collecting
          AstroGators to be part of the family members and we will share more information and benefits together.
        </p>
      </div>
    </div>
    <div className='overflow-hidden rotate-180'>
      <div className={`space-x-6 md:space-x-12 flex ${styles.slide}`}>
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1855.png' alt='AstroGator #1855' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1884.png' alt='AstroGator #1884' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1915.png' alt='AstroGator #1915' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1953.png' alt='AstroGator #1953' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1967.png' alt='AstroGator #1967' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1983.png' alt='AstroGator #1983' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-1994.png' alt='AstroGator #1994' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-3850.png' alt='AstroGator #3850' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-3876.png' alt='AstroGator #3876' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-3886.png' alt='AstroGator #3886' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-3897.png' alt='AstroGator #3897' />
        <img className={`rotate-180 ${styles.img}`} src='images/astrogator_token-3876.png' alt='AstroGator #3876' />
      </div>
    </div>
  </section>
);

export default Carousel;
