import { Web3Provider } from '@ethersproject/providers';

export interface IWeb3State {
  address?: string;
  web3Provider?: Web3Provider | null;
  provider?: any;
  connected?: boolean;
  chainId?: number;
}

export interface IWeb3Controller {
  address: string;
  web3Provider: Web3Provider | null;
  provider: any;
  connected: boolean;
  chainId: number;
  initConnect: () => void;
  resetApp: () => void;
}

export const INITIAL_STATE: IWeb3State = {
  address: '',
  web3Provider: null,
  provider: null,
  connected: false,
  chainId: 1,
};
