import WalletConnectProvider from '@walletconnect/web3-provider';
// import Fortmatic from 'fortmatic';
// import Portis from '@portis/web3';
import { WalletLink } from 'walletlink';

function getProviderOptions() {
  const providerOptions = {
    walletconnect: {
      package: WalletConnectProvider,
      options: {
        supportedChainIds: [1],
        rpc: { 1: process.env.REACT_APP_NETWORK_URL },
        bridge: 'https://uniswap.bridge.walletconnect.org',
        qrcode: true,
        pollingInterval: 15000,
      },
    },
    // 'custom-bscWalletconnect': {
    //   package: WalletConnectProvider,
    //   display: {
    //     name: 'BSC WalletConnect',
    //     description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    //   },
    //   options: {
    //     supportedChainIds: [56],
    //     rpc: { 56: 'https://bsc-dataseed1.ninicoin.io' },
    //     bridge: 'https://uniswap.bridge.walletconnect.org',
    //     qrcode: true,
    //     pollingInterval: 15000,
    //   },
    //   connector: async (ProviderPackage: any, options: any) => {
    //     const provider = new ProviderPackage(options);
    //     await provider.enable();
    //     return provider;
    //   },
    // },
    // fortmatic: {
    //   package: Fortmatic,
    //   options: {
    //     key: process.env.REACT_APP_FORTMATIC_KEY ?? '',
    //     chainId: 1,
    //   },
    // },
    // portis: {
    //   package: Portis,
    //   options: {
    //     id: process.env.REACT_APP_PORTIS_ID,
    //   },
    // },
    'custom-walletlink': {
      display: {
        name: 'Coinbase Wallet',
        description: 'Use Coinbase Wallet app on mobile device',
      },
      options: {
        appName: 'Hakka Finance',
        appLogoUrl:
          'https://raw.githubusercontent.com/hakka-finance/token-profile/e84d84e3345a9ef52c863a84867e9460a0ed1a40/images/0x0E29e5AbbB5FD88e28b2d355774e73BD47dE3bcd.png',
        networkUrl: process.env.REACT_APP_NETWORK_URL,
      },
      package: WalletLink,
      connector: async (
        _: any,
        options: {
          appName: string;
          appLogoUrl: string;
          networkUrl: string;
          chainId?: any;
        },
      ) => {
        const { appName, appLogoUrl, networkUrl, chainId } = options;
        const walletLink = new WalletLink({
          appName,
          appLogoUrl,
        });
        const provider = walletLink.makeWeb3Provider(networkUrl, chainId);
        await provider.enable();
        return provider;
      },
    },
  };
  return providerOptions;
}

export default getProviderOptions;
